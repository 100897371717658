// ... (previous imports)
import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
// ... (previous imports)

const App = () => {
  const [country, setCountry] = useState('');
  const [countryInfo, setCountryInfo] = useState(null);

  const handleInputChange = (event) => {
    setCountry(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(`https://restcountries.com/v3.1/name/${country}`);
      setCountryInfo(response.data);
    } catch (error) {
      console.error('Error fetching country information:', error);
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter a country"
          value={country}
          onChange={handleInputChange}
          className="text-input"
        />
        <button type="submit" className="submit-button">Get Country Info</button>
      </form>

      {countryInfo && (
        <div>
          <h2>Country Information</h2>
          <pre>{JSON.stringify(countryInfo, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default App;


